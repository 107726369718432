<template>
  <div class="Cumulative_achievements">
    <div class="cu-content">
      <div class="group">
        <div class="text">今日打卡</div>
        <div class="text tips">累计打卡：{{ total }}天</div>
      </div>
      <img
        class="close"
        src="../../assets/images/daily_clock/daka.png"
        alt=""
        @click="clock"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    this.getTotal();
  },
  methods: {
    getTotal() {
      this.$api.clock.total().then((res) => {
        this.total = res.data;
      });
    },
    clock() {
      this.$api.clock.add().then((res) => {
        this.total = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Cumulative_achievements {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  .cu-content {
    width: 50%;
    margin: 0 auto;
    background: url("../../assets/images/daily_clock/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 5vw;
    .group {
      font-size: 35px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #fefeff;
      .text {
        line-height: 3vw;
      }
      .tips {
        font-size: 23px;
      }
    }
    .close {
      margin-top: 1vw;
      cursor: pointer;
    }
  }
}
</style>
